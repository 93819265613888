<template>
  <div class="container">
    <div>
      <app-header></app-header>
    </div>
    <div class="body_container">
      <div class="view_container">
        <!--  左侧主菜单 -->
        <ul class="left_menus">
          <li v-for="(menu, index) in menus" :key="index" :class="{active: menu.active === $store.state.menuActive}"
              ref="menus"
              @click="selectedMenu(index)">{{ menu.label }}
          </li>
        </ul>
        <!-- 路由跳转容器   已布局好样式 -->
        <div class="router_view_container">
          <!-- 返回顶部 -->
          <back-top></back-top>

          <transition>
            <router-view></router-view>
          </transition>
        </div>
      </div>
      <app-footer class="app_footer"></app-footer>
    </div>

  </div>
</template>

<script>
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import {mapMutations} from "vuex";
import BackTop from "../../components/BackTop/BackTop";

export default {
  name: "AppContainer",
  components: {AppFooter, AppHeader, BackTop},
  data: () => ({
    // 首页待选项菜单
    menus: [
      {label: '首页', active: 0},
      {label: '快讯', active: 1},
      {label: '专题', active: 2},
      {label: '行业导航', active: 3},
      {label: 'NFT作品', active: 4}
    ],
  }),
  watch: {
    $route: {
      handler: function (val) {
        console.log(val);
      },
      deep: true
    }
  },
  created() {
    // 获取用户信息
    this.axios.get('/user/info').then(resInfo => {
      if (resInfo == undefined) return
      if (resInfo.code == 0) {
        this.setUserInfo(resInfo.data)
        this.setIsLogin(true)
      }
    })
  },
  methods: {
    ...mapMutations({
      setIsLogin: 'setIsLogin',
      setMenuActive: 'setMenuActive',
      setUserInfo: 'setUserInfo'
    }),
    // 主菜单点击 切换
    selectedMenu(index) {
      let menuActive = this.$store.state.menuActive
      let allRoutes = ['/index', '/fast-list', '/special-pre', '/industry-nav', '/works-list']
      let isCurPage = allRoutes.includes(this.$route.fullPath)
      if (menuActive == index && isCurPage) return
      if (index == 0) this.$router.push('/index')
      if (index == 1) this.$router.push('/fast-list')
      if (index == 2) this.$router.push('/special-pre')
      if (index == 3) this.$router.push('/industry-nav')
      if (index == 4) this.$router.push('/works-list')
      this.setMenuActive(index)
    },
  }
}
</script>

<style lang="scss" scoped>
.app_footer {
  position: relative;
  z-index: 999;
}
.container {
  position: relative;
  width: 100%;

  .body_container {
    min-width: 100%;
    background: #f8f8f8;
    box-sizing: border-box;
    position: absolute;
    top: 80px;


    .view_container {
      width: 1200px;
      margin: 0 auto;

      .left_menus {
        width: 110px;
        height: 260px;
        box-sizing: border-box;
        list-style: none;
        background: #FFFFFF;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 20px 0 0 0;
        border-radius: 4px;
        position: fixed;
        user-select: none;
        z-index: 99;
        font-family: "PingFangSC-Semibold","Helvetica Neue Bold", "Microsoft YaHei Bold", sans-serif;
        font-weight: bold;

        li {
          text-align: center;
          font-size: 18px;
          line-height: 40px;
          color: #000000;
          cursor: pointer;
          z-index: 5;
          transition: all 0.3s ease-in-out;

          &.active {
            color: #FFFFFF;
            background: #3E35E7;
            border-radius: 4px;
          }


          &:hover {
            background: #3E35E7;
            border-radius: 4px;
            color: #FFFFFF !important;
          }
        }

      }

      .router_view_container {
        padding-left: 130px;
        padding-top: 20px;
        min-height: calc(100vh - 322px);


        .v-enter-active,
        .v-leave-active {
          transition: all 0.1s ease;
        }
      }


    }
  }
}


</style>
