<template>
  <div class="back_top" ref="backTop" @click="backTop">
    <i class="fa fa-arrow-circle-up"></i>
  </div>
</template>

<script>
export default {
  name: "BackTop",
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed: function () {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      let topScroll = document.documentElement.scrollTop
      if (topScroll > 1550) {
        this.$refs.backTop.style.display = 'block'
      } else {
        this.$refs.backTop.style.display = 'none'
      }
    },
    backTop() {
      document.documentElement.scrollTop = 0
    },
  }
}
</script>

<style lang="scss" scoped>
.back_top {
  width: 60px;
  height: 60px;
  position: fixed;
  font-size: 60px;
  line-height: 60px;
  text-align: center;
  color: #C9C9C9;
  bottom: 10%;
  right: 15px;
  z-index: 999;
  cursor: pointer;
  display: none;
  animation:zooming 0.3s ease-in;

  @keyframes zooming{
    0%{
      transform: scale(0);
    }
    100%{
      transform: scale(1);
    }
  }

  &:before {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    background: #ededed;
    left: 10px;
    top: 10px;
    border-radius: 50%;
    z-index: -1;
  }
}

.back_top:hover {
  color: #3E35E7;

  &:before {
    background: #FFFFFF;
  }
}
</style>
