<template>
  <div class="footer_container">
    <div class="footer_content">
      <ul class="info_box">
        <li>
          <div class="title about" @click="aboutUS('us')">关于我们</div>
          <div><span @click="aboutUS('us')">元宇宙第一入口，引领新科技</span></div>
          <div>
            <div class="title contactUs">联系我们</div>
            <div div><span>邮箱: {{ footer.telphone }}</span></div>
          </div>
        </li>
        <li>
          <div class="title">合作伙伴</div>
          <div class="link-box">
            <div class="linkItems">
              <span><a href="https://www.ccvalue.cn/" target="_blank">碳链价值</a></span>
              <span><a href=" https://www.8btc.com/" target="_blank">巴比特</a></span>
               <span><a href="https://www.jinse.com/" target="_blank">金色财经</a></span>
              <span><a href="https://www.huoxing24.com/" target="_blank">火星财经</a></span>
            </div>
            <div class="linkItems">
              <span><a href="https://theblockbeats.info" target="_blank">律动BlockBeats</a></span>
              <span><a href="https://www.panewslab.com/zh/index.html" target="_blank">PANews</a></span>
             
              <span><a href="https://www.chainnews.com/" target="_blank">链闻</a></span>
            </div>
          </div>
         <!-- <div class="link-box">
            <div class="link-first">
              <div class="cooperation"><a href="https://www.ccvalue.cn/" target="_blank">碳链价值</a></div>
              <div class="cooperation"><a href="https://www.8btc.com/" target="_blank">巴比特</a></div>
              <div class="cooperation"><a href="https://www.jinse.com/" target="_blank">金色财经</a></div>
              <div class="cooperation"><a href="https://www.huoxing24.com/" target="_blank">火星财经</a></div>
            </div>
            <div class="link-last">
              <div class="cooperation"><a href="https://www.theblockbeats.com/" target="_blank">律动BlockBeats</a></div>
              <div class="cooperation"><a href="https://www.chainnews.com/" target="_blank">链闻</a></div>
              <div class="cooperation"><a href="https://www.panewslab.com/zh/index.html" target="_blank">PANews</a></div>
            </div>
         </div> -->
         
        </li>
       <li>
         <div class="title">关注我们</div>
         <div div><span>今日头条</span></div>
         <div div><span>腾讯新闻</span></div>
         <div div><span>知乎</span></div>
         <div div><span>Twitter</span></div>
         <div div><span>百家号</span></div>
       </li>
        
        <!-- <li class="icon_box">
          <div class="wechat_icon">
            <img class="weixin" src="../../assets/fIcon/weixin.png">
            <div class="pre_img_box">
              <img :src="footer.qrCode"/>
            </div>
          </div>
          <div class="zhihu_icon">
            <img class="zhihu" src="../../assets/fIcon/zhihu.png">
          </div>
          <div class="twitter_icon">
            <img class="tiw" src="../../assets/fIcon/tiw.png">
          </div>
          <div class="toutiao_icon">
            <img class="tout" src="../../assets/fIcon/tout.png">
          </div>
        </li> -->
        <li>
          <div class="erweima-box">
            <img :src="footer.qrCode" width="100" height="100"/>
            <div>扫码关注公众号</div>
          </div>
        </li>
      </ul>
      <div class="copy_right">
        <span>版权所有{{ footer.copyright }}</span>

        <span @click="skipIcpNmm">
          <img src="../../assets/fIcon/bNum.png"/>
          {{ footer.icpNum }}
        </span>
        <span @click="skipIcpNmm">
          蜀ICP备2021021779号
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppFooter",
  data: () => ({
    footer: {}
  }),
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.axios.get('/website/info').then(res => {
        this.footer = res.data
      })
    },
    aboutUS(strCode) {
      // let routeUrl = this.$router.resolve({path: "/about-us/" + strCode});
      // window.open(routeUrl.href, '_blank');
      this.$router.push({path: "/about-us/" + strCode})
    },
    skipIcpNmm() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank')
    },
  }
}
</script>

<style lang="scss" scoped>
.footer_container {
  z-index: 999;
  background: #2c2f39;
  width: 100%;
  font-family: Microsoft YaHei;
  font-size: 14px;

  .footer_content {
    width: 1200px;
    margin: 0 auto;

    .info_box {
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
      list-style: none;
      min-height: 180px;
      box-sizing: border-box;
      padding: 36px 0 42px;

      li {
        flex: 1;
        font-weight: 400;
        // padding-top: 36px;
        // overflow: hidden;
        margin-right: 120px;
        .link-box{
         span{
           a{
             text-decoration: none;
             color: #ABAEB9;
            margin-right: 16px;
           }
           
         }
         .linkItems{
          //  border: 1px solid red;
         }
         .linkItems span:last-child a{
           margin-right:0px;
         }
        }

        .title {
          color: #D3D6E1;
          font-size: 16px;
          line-height: 16px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 10px;
        }
        .contactUs{
          margin-top: 22px;
        }
        .cooperation{
          a{
            color: #ABAEB9;
            text-decoration:none;
            font-size: 14px;
            &:hover{
              color: #3E35E7;
              cursor:pointer;
            }
          }
        }

        .about {
          cursor: pointer;
        }

        .title ~ div {
          font-size: 14px;
          color: #ABAEB9;
          line-height: 30px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          & span:hover {
            color: #3E35E7;
            transition: all 0.1s ease-in;
            cursor:pointer;
          }
        }
      }

      .erweima-box{
        width: 100px;
        margin-left: 17px;
        div{
          text-align: center;
          margin-top: 5px;
          font-size: 14px;
           color: #ABAEB9;
        }
      }

      // .icon_box {
      //   display: flex;
      //   justify-content: center;
      //   overflow: visible;

      //   > div {
      //     height: 48px;
      //     cursor: pointer;
      //     position: relative;
      //   }

      //   & div:not(:last-child) {
      //     margin-right: 16px;
      //   }

      //   .wechat_icon .pre_img_box {
      //     position: absolute;
      //     width: 100px;
      //     height: 98px;
      //     background: #FFFFFF;
      //     left: 50%;
      //     top: calc(-100% - 56px);
      //     transform: translate(-50%, 0);
      //     border-radius: 4px;
      //     box-shadow: 0 0 2px #DCDCDC;
      //     visibility: hidden;
      //     opacity: 0;

      //     img {
      //       margin: 5px 6px;
      //       width: 88px;
      //       height: 88px;
      //       object-fit: cover
      //     }
      //   }

      //   .wechat_icon .pre_img_box:before {
      //     content: '';
      //     width: 10px;
      //     height: 10px;
      //     position: absolute;
      //     background: #FFFFFF;
      //     border-bottom-right-radius: 1px;
      //     bottom: -5px;
      //     left: 50%;
      //     transform: translateX(-50%) rotate(45deg);
      //   }

      //   .wechat_icon:hover {
      //     .pre_img_box {
      //       visibility: visible;
      //       opacity: 1;
      //       top: calc(-100% - 62px);
      //       transition: opacity 0.2s ease-in-out, top 0.3s ease-in-out
      //     }

      //     .weixin {
      //       content: url(../../assets/fIcon/weixin_pre.png);
      //     }
      //   }

      //   .zhihu_icon:hover .zhihu {
      //     content: url(../../assets/fIcon/zhihu_pre.png);
      //   }

      //   .twitter_icon:hover .tiw {
      //     content: url(../../assets/fIcon/tiw_pre.png);
      //   }

      //   .toutiao_icon:hover .tout {
      //     content: url(../../assets/fIcon/tout_pre.png);
      //   }
      // }
    }

    .copy_right {
      text-align: center;
      border-top: 1px solid #3F4452;
      color: #666a78;
      height: 40px;
      line-height: 40px;
      font-size: 13px;
      img {
        position: relative;
        width: 20px;
        height: 20px;
        object-fit: cover;
        top: 3.5px;
        margin: 0 4px 0 0;
      }

      :last-child {
        margin-left: 30px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
</style>
