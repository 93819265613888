<template>
  <div class="header_container">
    <div>
      <div class="logo_area">
        <img :src="appHeader.logoUrl" @click="$router.push({name:'Home'})"/>
      </div>
      <div class=" tool">

        <div class="search_input_area">

          <div class="search_input_box">

            <input v-model="searchInput" ref="search_input" @focus="searchFocus"
                   @keydown.enter="toSearch(searchInput)"
                   @input="getSearchSuggest" @blur="searchBlur"/>
            <i class="fa fa-search" @click="() => {this.$refs.search_input.focus()}"></i>

            <div class="search_result_box" ref="search_result_box" @mouseenter="searchBoxMouseenter"
                 @mouseleave="searchBoxMouseleave">
              <div class="history_tools">
                <span>搜索历史</span>
                <span @click="clearHistory">清空</span>
              </div>
              <div class="history_tags">
                <el-tag
                    :key="index"
                    v-for="(tag,index) in historyTag"
                    closable
                    type='info'
                    :disable-transitions="false"
                    @click.native="searchHistory(tag)"
                    @close="historyTagClose(index)">{{ tag }}
                </el-tag>
              </div>
              <div class="search_type">
                <span :class="{'activeType': selectTopType == 'hotArticleList'}"
                      @click="selectTopType = 'hotArticleList'">热门文章</span>
                <span :class="{'activeType': selectTopType == 'fastNewsList'}" @click="selectTopType = 'fastNewsList'">热门快讯</span>
              </div>
              <ul class="search_top">
                <template v-if="selectTopType == 'hotArticleList'">
                  <li v-for="(item, index) in hotArticleList" :key="index" @click="skipArticleDetails(item)">
                    <span class="number">{{ index + 1 }}</span>
                    {{ item.title }}
                  </li>
                </template>
                <template v-else>
                  <li v-for="(item, index) in fastNewsList.slice(0,10)" :key="index" @click="skipFastDetails(item)">
                    <span class="number">{{ index + 1 }}</span>
                    {{ item.title }}
                  </li>
                </template>
              </ul>
            </div>
          </div>
        </div>


        <div class="handle_tool" style="position: relative">

          <!--          <button v-if="!$store.state.isLogin" class="login_btn" @click="login">登录 / 注册</button>-->

          <!--          <div v-else style="position: relative;">-->
          <!--            <el-dropdown @command="logOut">-->
          <!--              <img class="img_box" src="../../assets/image/defautHeader.png"/>-->
          <!--              <el-dropdown-menu slot="dropdown">-->
          <!--                <el-dropdown-item command="logOut">退出登录</el-dropdown-item>-->
          <!--              </el-dropdown-menu>-->
          <!--            </el-dropdown>-->
          <!--          </div>-->

        </div>
      </div>

      <popup v-if="isShow" :is-show.sync="isShow"></popup>
    </div>
  </div>
</template>

<script>
import Popup from "../../views/popup/Popup";

let timer;

export default {
  name: "AppHeader",
  components: {
    Popup,
  },
  data: () => ({
    // 导航栏对象
    appHeader: {},
    searchInput: "",
    isShow: false,
    isHoverMenus: false,
    article: [],
    fast: [],
    special: [],
    isHoverSearchBox: false,
    isFocusSearchInput: false,
    // 热门文章
    selectTopType: 'hotArticleList',
    hotArticleList: [],
    fastNewsList: [],
    historyTag: []
  }),
  created() {
    this.getList();
    this.getHotArticleList();
    this.getNews();
    this.historyTag = JSON.parse(localStorage.getItem('historyTag'))
  },
  methods: {
    login() {
      this.isShow = true;
    },
    getList() {
      this.axios.get('/website/info').then(res => {
        this.appHeader = res.data
      })
    },
    // 获取搜索建议
    getSearchSuggest() {
      // 防抖
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        // 处理事件
        this.axios.get('/article/search', {params: {text: this.searchInput}}).then(res => {
          this.article = res.article
          this.fast = res.fast
          this.special = res.special
        })
        timer = undefined;
      }, 400);

    },
    searchFocus() {
      // 清空搜索结果
      this.article = []
      this.fast = []
      this.special = []
      this.isFocusSearchInput = true
      this.$refs.search_input.style.width = '460px'
      this.$refs.search_input.style.boxShadow = '0 0 0 0.1rem #333'
      this.$refs.search_result_box.style.display = "block";
    },
    searchBlur() {
      this.isFocusSearchInput = false
      if (!this.isHoverSearchBox) {
        this.$refs.search_input.style.width = '60px'
        this.$refs.search_input.style.boxShadow = 'none'
        this.$refs.search_result_box.style.display = "none";
        this.searchInput = ''
      }
    },
    searchBoxMouseenter() {
      this.isHoverSearchBox = true
    },
    searchBoxMouseleave() {
      this.isHoverSearchBox = false
      if (!this.isFocusSearchInput) {
        this.$refs.search_input.style.width = '60px'
        this.$refs.search_input.style.boxShadow = 'none'
        this.$refs.search_result_box.style.display = "none";
        this.searchInput = ''
      }
    },
    skipFastDetails(item) {
      let routeUrl = this.$router.resolve({path: '/fast-detail/' + item.atUuid});
      window.open(routeUrl.href, '_blank');
      this.searchBoxMouseleave()
    },
    skipArticleDetails(item) {
      let routeUrl = this.$router.resolve({path: '/article-detail/' + item.atUuid});
      window.open(routeUrl.href, '_blank');
      this.searchBoxMouseleave()
    },

    logOut() {
      let that = this
      this.axios.get('/user/logout').then(res => {
        if (res.code == 0) {
          localStorage.removeItem('token')
          that.$store.commit('setIsLogin', false)
        }
      })
    },
    getHotArticleList() {
      let params = {
        currentPage: 1,
        currentPageSize: 10,
        type: ''
      }
      this.axios.get('/index/article-hot', {params}).then(res => {
        this.hotArticleList = res.page.list
      })
    },
    getNews() {
      this.axios.get('/index/fast').then(res => {
        this.fastNewsList = res.page.list
      })
    },
    toSearch(val) {
      let tempArr = JSON.parse(localStorage.getItem('historyTag'))
      if (tempArr == null) {
        localStorage.setItem('historyTag', JSON.stringify([val]))
      } else {
        if (tempArr.indexOf(val) == -1) {
          tempArr.push(val)
          localStorage.setItem('historyTag', JSON.stringify(tempArr))
        }
      }
      this.historyTag = tempArr
      this.$router.push('/search-list/' + val)
      this.searchBlur()
    },
    searchHistory(tag) {
      this.searchInput = tag
      this.$router.push('/search-list/' + tag)
    },
    // tag标签关闭
    historyTagClose(i) {
      this.historyTag.splice(i, 1)
      localStorage.setItem('historyTag', JSON.stringify(this.historyTag))
    },
    clearHistory() {
      this.historyTag = []
      localStorage.setItem('historyTag', JSON.stringify([]))
    }
  },
};
</script>

<style lang="scss" scoped>
.header_container {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 900;

  > div {
    width: 1200px;
    display: flex;
    justify-content: space-between;

    .logo_area {
      display: flex;
      align-items: center;

      img {
        width: 400px;
        height: 50px;
        object-fit: contain;
        position: relative;
        left: -74px;
        cursor: pointer;
      }
    }

    .tool {
      display: flex;
      align-items: center;

      .search_input_area {
        .search_input_box {
          position: relative;

          input {
            width: 60px;
            height: 40px;
            box-sizing: border-box;
            transition: all 0.3s ease-in-out;
            border: none;
            box-shadow: transparent;
            border-radius: 4px;
            padding-left: 30px;
            outline: none;
            font-size: 16px;
          }

          input:focus {
            border: none;
            max-width: 460px;
            border-radius: 5px;
            overflow: hidden;
          }

          .fa-search {
            position: absolute;
            font-size: 24px;
            left: 5px;
            top: -2px;
            transform: translateY(40%);
            color: #333;
            cursor: text;
          }

          .search_result_box {
            //min-height: 500px;
            height: 530px;
            overflow-y: scroll;
            width: 100%;
            position: absolute;
            font-family: Microsoft YaHei;
            background: #ffffff;
            box-sizing: border-box;
            box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
            transition: all 0.4s ease-in;
            left: 0;
            top: 42.1px;
            border-radius: 4px;
            display: none;
            padding: 10px 10px 30px 16px;


            .history_tools {
              font-size: 14px;
              color: #333333;
              display: flex;
              justify-content: space-between;
              margin: 10px 0;

              :first-child {
                font-weight: bold
              }

              :last-child {
                color: #999999;
                cursor: pointer;
              }
            }

            .history_tags {
              display: flex;
              flex-wrap: wrap;
              min-height: 42px;
              cursor: pointer;

              .el-tag {
                margin-right: 10px;
                margin-bottom: 10px
              }
            }

            .search_type {
              margin: 10px 0;

              span {
                color: #999999;
                cursor: pointer;
                transition: all 0.1s ease-in-out;
              }

              span:first-child {
                margin-right: 38px;
              }

              .activeType {
                color: #333333;
                font-weight: bold;
              }
            }

            .search_top {
              li {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 36px;
                cursor: pointer;
                border-radius: 4px;

                &:hover {
                  background: #f2f2f2;
                }

                .number {
                  display: inline-block;
                  min-width: 20px;
                  text-align: center;
                  margin-right: 5px;
                }

                &:first-child .number {
                  color: #EE9D00;
                }

                &:nth-child(2) .number {
                  color: #CCCCCC;
                }

                &:nth-child(3) .number {
                  color: #C97828;
                }

                &:nth-child(n - 3) {
                  color: #666666;
                }
              }
            }

            h5 {
              margin-bottom: 8px;
            }

            h5:not(:first-child) {
              margin-top: 8px;
            }

            li > p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 0.8rem;
              line-height: 1.8rem;
              cursor: pointer;
              margin: 0;
              border-radius: 4px;
              left: -10px;
              position: relative;
              padding-left: 10px;

              &:hover {
                background: #f2f2f2;
              }
            }
          }

          /deep/ ::-webkit-scrollbar {
            width: 10px;
            background-color: #FFFFFF;
          }
        }
      }

      .handle_tool {
        text-align: center;
        position: relative;
        margin-left: 20px;
        padding-left: 20px;

        &:before {
          content: "";
          width: 2px;
          height: 15px;
          position: absolute;
          background: #f2f2f2;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        .login_btn {
          padding: 0;
          margin: 0;
          text-underline: none;
          background: transparent;
          border: none;
          height: 40px;

          font-size: 20px;
          font-weight: bold;
          color: #000000;
          letter-spacing: 1px;
          cursor: pointer;
        }

        img.img_box {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
          cursor: pointer;
        }

        .dropdown_menu {
          min-width: 120px;
          position: absolute;
          left: calc(50% + 15px);
          background: #ffffff;
          transform: translateX(-50%);
          margin: 10px 0;
          z-index: 99;
          box-shadow: 0 0 2px #f3efef;
          border-radius: 4px;
          display: none;
          transition: all 0.3ms ease-in-out;

          li {
            margin: 4px 6px;
            padding: 6px 10px;
            cursor: pointer;

            &:hover {
              background: #f2f2f2;
              color: #3e34eb;
            }


          }

          //&::before {
          //  content: '';
          //  width: 10px;
          //  height: 10px;
          //  position: absolute;
          //  background: #ffffff;
          //  //border-left: 1px solid #000000;
          //  //border-top-left-radius: 2px;
          //  //border-top: 1px solid #000000;
          //  transform: rotate(45deg) translateY(-84%);
          //  z-index: -99;
          //  left: 45%;
          //}
        }
      }
    }
  }
}
</style>
